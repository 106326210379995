.blue {
  color: #2596be !important;
  display: inline;
}

.c-button {
  margin: 5px 2px 5px 2px !important;
}

.c-manager {
  width: 300px;
}

.c-manager-div {
  width: 300px;
  display: inline-table;
  margin-top: 5px;
}

.c-manager-long {
  width: 600px;
}

.c-manager-payment {
  width: 185px;
}

.c-manager-status {
  width: 200px;
}

.c-view-config {
  width: 150px;
}

.c-view-config-long {
  width: 250px;
}

.flex {
  display: flex !important;
}

.left-td {
  text-align: right !important;
  color: #2596be !important;
  font-weight: bold !important;
}

.logo {
  width: 120px;
  display: block;
  float: right;
}

.main-div {
  margin: 10px;
}

.MuiFormControlLabel-root {
  height: 20px;
}

.red {
  color: red !important;
}

.title {
  color: #2596be !important;
  text-align: center;
}